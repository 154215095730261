import React from 'react';
import './imageGradient.css';
import appSE from '../../../assets/appSE.png'
const ImageGradient = (props) => {
    return (
        <div className='imageGradient'>
            <img src={props.img} alt="App" className='imgPres'/>
                <div className="gradApp-circle-pink"></div>
                <div className="gradApp-circle-blue"></div>
        </div>
    );
};

export default ImageGradient;