
const SMTPJS_cred = {
    'pass':"9039247CAFC3ED82E365A9010FF3BD37BCEF",

}

const elasticMail = {
    'ApiKey': "963CC364BA7D6196654D8088F6A92BF57F7B71CA1A0EADDC5B50AFB130522EBE84937FFE1785FFA2D368CA2C709AC530"
}

 

const ElasticEmail = require('@elasticemail/elasticemail-client');

const client = ElasticEmail.ApiClient.instance;

/* Generate and use your API key */
const apikey = client.authentications['apikey'];
apikey.apiKey = elasticMail['ApiKey'];

/**
 * Send transactional emails
 * Example api call that sends transactional email.
 * Limit of 50 maximum recipients.
 */

const emailsContactsApi = new ElasticEmail.ContactsApi();
const unsubscribe = (email)=>{
    const contacts = {
        Emails: [email]
    };
    const callback = (error, data, response) => {
        if (error) {
            console.error(error);
        } else {
            console.log('API called successfully.');
            console.log('Contacts deleted.');
        }
    };
    emailsContactsApi.contactsDeletePost(contacts, callback);    
}

export const unsubscribeAPI = (email)=>{unsubscribe(email)} //TODO: fix it