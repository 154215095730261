import React from 'react';
import profile from '../../assets/profile.png'
import './who.css';
import { Grid } from '@mui/material';
import tensorflow from '../../assets/icons/tensorflow.png';
import pytorch from '../../assets/icons/pytorch.png';
import python from '../../assets/icons/python.png';
import react from '../../assets/icons/react.png';
import flutter from '../../assets/icons/flutter.png';
import java from '../../assets/icons/java.png';
import { Stack } from '@mui/system';

function Who() {
    return (
            <Grid 
            container 
            alignItems={"center"} 
            justifyContent={"space-evenly"}
            justify={"flex-center"}
            display={"flex"}
            flexDirection={"column"}
            gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
            color={"#fff"}
        >  
                
                <img src={profile} alt="Logo" width="150" zIndex="2" unselectable='on' className='logo' style={{paddingBottom:"40px", paddingTop:"20px"}}/>
                <div className='intro' style={{fontSize:"1.5rem"}}>
                    <div className="divider"></div>
                    
                            <h1><b>Who are you?👀</b></h1>
                        <p className='paragraph'>
                            Hi, I am Marco, <br/>
                            a computer engineering student at Unical (University) who loves to code and create new things. <br/>
                            I am passionate about the ai world (yes, even before chatgpt came out).
                            I love building neural networks, expecially in nlp, but I also love create apps and websites, with particular attention to the user experience. <br/>
                            <b>My skills:</b>
                            <Stack
                                alignItems="center" 
                                direction="row" 
                                gap={2}
                                marginTop={"20px"}
                                justifyContent={"space-evenly"}>
                                <img src={python} alt="Python" width="50" zIndex="2" unselectable='on'/>
                                <img src={tensorflow} alt="Tensorflow" width="50" zIndex="2" unselectable='on'/>
                                <img src={pytorch} alt="Pytorch" width="50" zIndex="2" unselectable='on'/>
                                <img src={react} alt="React" width="50" zIndex="2" unselectable='on'/>
                                <img src={flutter} alt="Flutter" width="50" zIndex="2" unselectable='on'/>
                                <img src={java} alt="Java" width="50" zIndex="2" unselectable='on'/>
                            </Stack>
                            <br/>
                            <br/>
                        </p>
                        <h1><b>My mission✨</b></h1>
                        <p className='paragraph'>
                            I created Mysquareai to help people find anyone to work with or simply enjoy their time. <br/>
                            <br/>
                            The idea come to my mind a long time ago, when I had an idea for another app to automate selling through ai, but I struggled <br/>
                            to find someone to work with:<br/><br/>- my friends where busy🙅🏻,<br/>- or they didn't have my same vision🤔, <br/>- or my same focus🧐. <br/><br/>
                            I immediately thought that all of this needed a solution, and I started to work on it. <br/>
                            <br/>
                            I hope you will enjoy it as much as I do. <br/>
                            <br/>
                            <h1><b>My vision🚀</b></h1>
                            <br/>
                            👥 I want to make a platform as enjoyable as possible, most focused on networking and collaboration. <br/><br/>
                            🥳 Possibilities are endless! I always want that it will remain a place not only for work, but also for fun. <br/><br/>
                            💸 But, if you want to use it in a professional way, I am already thinking about future updates to help you even raise funds for your projects! <br/><br/>
                            💬👀 And, maybe, who knows, also a professional chat!
                            <br/>
                            <br/>

                            <h1><b>Do not esitate to contact me😉</b></h1>
                            <br/>
                            Want to work together? Since my app is not completely ready yet, let's do in the fashioned old way: <b>marco.deluca.projects@gmail.com</b>
                        </p>
                    <div className="who-circle-blueintro"></div>
                    <div className="who-circle-pinkintro"></div>
                </div>
                    <div className="who-circle-pinkintro"></div>
            </Grid>
    );
}

export default Who;