
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCw1psn-mj0SYB4r9fTcWY90XNRH1vh8wg",
  authDomain: "mysquare-32272.firebaseapp.com",
  projectId: "mysquare-32272",
  storageBucket: "mysquare-32272.appspot.com",
  messagingSenderId: "73274370241",
  appId: "1:73274370241:web:9a3b044ed34afc47e38c40",
  measurementId: "G-DFEQZ91VLH"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);