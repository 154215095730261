import React, {useState} from 'react';
import './form.css';
import { collection, addDoc, getDocs,doc, getFirestore, query, setDoc } from "firebase/firestore";
import {getDatabase} from 'firebase/database';
import {db} from '../../firebase';
import { Firestore } from 'firebase/firestore';
import { Query } from 'firebase/firestore';
import { where } from 'firebase/firestore';
import brkenHeart from '../../assets/misaligned_brokenHeart.png';
import toast, { Toaster } from 'react-hot-toast';
import { Stack } from '@mui/material';
import UilInstagram from '@iconscout/react-unicons/icons/uil-instagram.js';
import UilYoutube from '@iconscout/react-unicons/icons/uil-youtube.js';
import UilTwitter from '@iconscout/react-unicons/icons/uil-x.js';
import { useHistory } from 'react-router-dom';
import { unsubscribeAPI } from '../../services/unsubscribe';

const Unsubscribe = () => {
    const [email, setEmail] = useState('');
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
  
    const unsubscribeFirestore = async (email) => {
      try {
        // Check if a document with the provided email already exists
        // const dbs = getFirestore()
        const c = collection(db, "email_landing")
        const querySnapshot = query(c, where("email", "==", email));
        const docs = await getDocs(querySnapshot);
        if (docs.empty) {
            console.log("Email  to unsubscribe exists in Firestore");
            return toast('You are not subscribed...', {
              icon: '😢',
            }); // Exit the function if email already exists
        }
        docs.forEach(async (docc) =>{
            await setDoc(doc(c, docc.id), {
            email: email,
            newsLetter: false, //TODO: Unsubscribe feature
            });
        });
        console.log('Email written to Firestore');
        try{
          unsubscribeAPI(email)
        }catch(error){console.error(error)}
        return toast('Done', {
          icon: '💔',
        });
      } catch (error) {
        console.error('Error writing email to Firestore: ', error);
        return toast('Error unsubscribing', {
          icon: '❌',
        });
      }
    };

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    
    const handleSubmit = async (event) => {
      event.preventDefault(); // Prevents the default form submission behavior
      if(email.trim()==''){
        console.log('Email cannot be empty');
        return toast('Empty email', {
          icon: '🤔',
        });
      }
      if(!isValidEmail(email)){
        return toast('not an email', {
          icon: '🤔',
        });
      }
      await unsubscribeFirestore(email);
      setEmail(''); // Clear the input field after submitting
  
  };
  
    return (
        <div className="container">
            <div className="divider"></div>
            <img src={brkenHeart} alt="brokenHeartIcon" className="emailIcon"/>
            <h1>I am sorry you have to go...</h1>
            <p className='parForm'>Write the email in this form to leave the newsletter😢</p>
                <div className="form-group">
                    <input type="email" id="email" name="email" placeholder='Email' className='emailField' value={email} onChange={handleEmailChange}/>
                    <button type="submit" className='newsLBtn' onClick={handleSubmit}>Submit</button>
                </div>
            <h2>Follow us if you want🥹</h2>
            <Toaster/>
            <Stack
              alignItems="center" 
              direction="row" 
              gap={3}
              color={"#fff"}
              justifyContent={"center"}>
                <div className='Icon' color='#fff'><UilInstagram width="90%" height="90%" color="#fff"></UilInstagram></div>
                <UilYoutube width="50px" height="50px"></UilYoutube>
                <UilTwitter width="50px" height="50px"></UilTwitter>
            </Stack>
            <div style={{paddingBottom:"5%"}}></div>
        </div>
    );
};

export default Unsubscribe;