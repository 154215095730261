import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import logo from "../../assets/mysquare_onlytext_logo.png"
import './devblog.css'

const DevBlog =()=> { //TODO responsive for phones
  return (
    <div color='#fff' style={{justifyContent:"space-between", flexDirection:"row", width:"100%", alignItems:"center"}}>
        <div className="circle-container-pink"><div className="circle-pink"/></div>
        <div className="circle-container-blue"><div className="circle-blue"/></div>
        <div style={{alignItems:"center", display:"flex", flexDirection:"column", width:"100%"}}>
            <img src={logo} alt="Logo" width="40" zIndex="2" unselectable='on' className='logo' style={{paddingBottom:"40px", paddingTop:"20px"}}/>
        </div>
        <div style={{display:"flex", backgroundColor:"#000", flexDirection:"column", alignItems:"center", color:"#fff"}}>
            <Typography variant="h4" component="h1" textAlign="center" zIndex="2">In this journey together🥳</Typography>
        </div>
        <Timeline position="alternate" color="#fff">
        <TimelineItem>
            <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="#fff"
            >
            How it started
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" color="#fff">
                Idea💡
            </Typography>
                <Typography color="rgba(255,255,255,0.8)" className='textToLimit'>I always had a lot of project to make, but I never had the right people to work with,
                so I decided to create a platform to connect people with the same interests.
                </Typography>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem >
            <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant="body2"
            color="#fff"
            >
            The concept
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" color="#fff">
                Initial code💻
            </Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <Typography className='textToLimit'>The system first, and I made a concept about a communication between your prompt and the database (API)
                with the ai system to understand better any request of yours</Typography>
            </div>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant="body2"
            color="#fff"
            >
            The User Experience
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot variant="outlined">
            </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" color="#fff">
                Design🎨
            </Typography>
            <Typography className='textToLimit'>Now, I was sure about what I need to do, so I started to build 
            a beautiful expression for the system, minimalism and friendly were my keywords for every step I was taking
            </Typography>
            </TimelineContent>
        </TimelineItem>
        <TimelineItem>
            <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            variant="body2"
            color="#fff"
            >
            The presentation
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            <TimelineDot color="secondary">
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2}}>
            <Typography variant="h6" component="span" color="#fff">
                Landing page🚀
            </Typography>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Typography className='textToLimit'>Now I was ready to start my presentation to the world: the website that you are watching right now! 
            The platform is not finished, because I want to know your opinion to make it better every day! For me is so important, <a href="https://forms.gle/iyLvFLcpbuD3Dv338" target="_blank" color='rgba(255, 0, 190, 10)'>click here</a> if you want to contribute🥳</Typography>
            </div>
            </TimelineContent>
        </TimelineItem>
        </Timeline>
    </div>
  );
}
export default DevBlog;