import CatchyPhrasePage from "./catchyPage";
import React from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";
import AppPresentation from "./AppPresentation";
import UilCompass from '@iconscout/react-unicons/icons/uil-compass.js';
import UilView from '@iconscout/react-unicons/icons/uil-eye-slash.js';
import FancyText from '@carefully-coded/react-text-gradient';
import appSE from '../../assets/appSE.png'
import appT from '../../assets/appTopics.png'
import UilFire from '@iconscout/react-unicons/icons/uil-fire.js';
import UilBrain from '@iconscout/react-unicons/icons/uil-brain.js';
import Form from "./form.jsx";
import IntroMysquare from "./introMysquare.jsx";
const Home = () => {
    const rowsSE = 
    [
        {
            icon: <UilCompass width="90%" height="90%"></UilCompass>,
            description: 'Discover in infinite different topics, the right person for you. Everyone that you are searching for is only waiting for your text.'
        },
        {
            icon: <UilBrain width="90%" height="90%"/>,
            description: 'No more waiting for the right people.\n Just type and make a semantic search.\n Right on point.'
        }
    ];
    const titleSE =   <h2>The first&nbsp;
                        <FancyText
                        gradient={{ from: '#F858E0', to: '#77156C', type: 'linear' }}
                        animateTo={{ from: '#6DEDD0', to: '#7AE23A' }}
                        animateDuration={2000}
                        >human&nbsp;</FancyText>
                        search engine.
                    </h2>;
    
    const imgSE = appSE
    
    const rowsT= 
    [
        {
            icon: <UilFire width="90%" height="90%"></UilFire>,
            description: 'Look what is going on, the people that talk about it the most. You may be one of them.'
        },
        {
            icon: <UilView width="90%" height="90%"/>,
            description: 'Do not wait any algorithm, do not spam content to be seen. If the more you are talking about it, the more you deserve it.'
        }
    ];
    const titleT=   <h2>Create something&nbsp;
                        <FancyText
                        infinite={true}
                        gradient={{ from: '#F858E0', to: '#77156C', type: 'linear' }}
                        animateTo={{ from: '#6DEDD0', to: '#7AE23A' }}
                        animateDuration={2000}
                        >beautiful</FancyText>
                        .
                    </h2>;
    
    const imgT= appT


  
    return (
        <div>
            <ResponsiveAppBar></ResponsiveAppBar>
            <CatchyPhrasePage></CatchyPhrasePage>
            <div style={{paddingBottom:"10%"}}></div>
            <IntroMysquare id="IntroMysquare"></IntroMysquare>
            <div style={{paddingBottom:"10%"}}></div>
            <AppPresentation 
                img={imgSE}
                title={titleSE}
                rows={rowsSE}
            ></AppPresentation>
            <div style={{paddingBottom:"20%"}}></div>
            <AppPresentation 
                rightImg={true} //TODO: fix not stateful
                img={imgT}
                title={titleT}
                rows={rowsT}
            ></AppPresentation>
            <div style={{paddingBottom:"10%"}}></div>
            <Form></Form>
        </div>
    );
  }
  
  export default Home;