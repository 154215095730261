import * as React from 'react';
import appSE from '../../assets/appSE.png'
import appT from '../../assets/appTopics.png'
import './AppPresentation.css'
import Spline from '@splinetool/react-spline';
import Grid from '@mui/material/Grid';
import { List } from '@mui/material';
import ImageGradient from './features/imageGradient.jsx';
import FeaturesRow from './features/featuresRow.jsx';
import * as Unicons from '@iconscout/react-unicons';
import FancyText from '@carefully-coded/react-text-gradient';
import {isMobile} from 'react-device-detect';

const AppPresentation = (props) => {
    const img = props.img;
    const title = props.title;
    const rows = props.rows;
    const rightImg = props.rightImg;
    
    if(isMobile && rightImg){
        return (
            <Grid 
                container 
                alignItems={"center"} 
                justifyContent={"space-evenly"}
                justify={"flex-end"}
                display={"flex"}
                className='appPresentation'
                gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
                color={"#fff"}
            >  
                    <ImageGradient img={img}/>
                    <FeaturesRow 
                        title={title}
                        rows={rows}
                    ></FeaturesRow>
            </Grid>
        );
    }
    if(rightImg){
        return (
            <Grid 
                container 
                alignItems={"center"} 
                justifyContent={"space-evenly"}
                justify={"flex-start"}
                display={"flex"}
                className='appPresentation'
                gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
                color={"#fff"}
            >  
                    <FeaturesRow 
                        title={title}
                        rows={rows}
                    ></FeaturesRow>
                    <ImageGradient img={img}/>
            </Grid>
        );
    }

    return (
            <Grid 
                container 
                alignItems={"center"} 
                justifyContent={"space-evenly"}
                justify={"flex-end"}
                display={"flex"}
                className='appPresentation'
                gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
                color={"#fff"}
            >  
                    <ImageGradient img={img}/>
                    <FeaturesRow 
                        title={title}
                        rows={rows}
                    ></FeaturesRow>
            </Grid>
    );
};

export default AppPresentation;