import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import Grid from '@mui/material/Grid';
import './featuresRow.css';
import { Stack } from '@mui/material';
const FeaturesRow = (props) => {
    const featureRows = props.rows;
    return (
        <div className="features-row">
                {props.title}
                {featureRows.map((row) => (
                    <Stack 
                        alignItems="center" 
                        direction="row" 
                        gap={2}
                        justifyContent={"center"}
                    >
                            <div className='Icon'>{row.icon}</div>
                            <div className='textb'>
                                <p className="feature-description">{row.description}</p>
                            </div>
                    </Stack>
                ))}
        </div>
    );
};

export default FeaturesRow;