import logo from './logo.svg';
import './App.css';
import CatchyPhrasePage from './pages/Home/catchyPage';
import Home from './pages/Home/HomePage';
import firebase from 'firebase/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Switch } from '@mui/material';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React from "react";
import Form from './pages/Home/form';
import Unsubscribe from './pages/Home/Unsubscribe';
import DevBlog from './pages/devblog/devblog';
import Who from './pages/who/who';

function App() {
  // Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCw1psn-mj0SYB4r9fTcWY90XNRH1vh8wg",
  authDomain: "mysquare-32272.firebaseapp.com",
  projectId: "mysquare-32272",
  storageBucket: "mysquare-32272.appspot.com",
  messagingSenderId: "73274370241",
  appId: "1:73274370241:web:9a3b044ed34afc47e38c40",
  measurementId: "G-DFEQZ91VLH"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
  return (
      <Routes>
        <Route path="/" Component={Home}>
          <Route index Component={Home} />
        </Route>
        <Route path="/unsubscribe" Component={Unsubscribe} />
        <Route path="/subscribe" Component={Form} />
        <Route path="/devblog" Component={DevBlog} />
        <Route path="/who" Component={Who} />
      </Routes>
  );
}

export default App;
