import React from 'react';
import './catchyPhrasePage.css'; // Import your CSS file
import logo from "../../assets/mysquare_onlytext_logo.png"
import UilSearchIcon from '@iconscout/react-unicons/icons/uil-search-alt'
import { TypeAnimation } from 'react-type-animation';
import { TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import {ReactComponent as GooglePlayBadge} from '../../assets/google-play.svg';
import {ReactComponent as AppStoreBadge} from '../../assets/app-store.svg'
import { height } from '@mui/system';
const CatchyPhrasePage = () => {
  const scrollToNext = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight/5,
      behavior: 'smooth',
    });
};
  return (
    <div className="catchy-phrase-container">
      <div className="circle-container-pink">
        <div className="circle-pink"></div>
      </div>
      <div className="circle-container-blue">
        <div className="circle-blue"></div>
      </div>
      <img src={logo} alt="Logo" width="40" zIndex="2" unselectable='on' className='logo'/>
      <div className="content">
        <div className="spacer"></div>
        <h1>Nobody understands you</h1>
        <h2>Until you find the right people</h2>
        <button className="search-button" onClick={scrollToNext}>
              <TypeAnimation className='TypeAnim'
                sequence={[
                  1000,
                  "I am searching a friend", 
                  1000,
                  "I am searching my soulmate", 
                  1000,
                  "I am searching my co-founder", 
                  1000,
                  "I am searching my mentor", 
                  1000,
                  "I am searching my team",
                  1000
                ]}
                speed={50}
                //wrapper="span"
                // style={{ fontSize: "2em", fontFamily:'Ubuntu', display: 'inline-block'}}
                repeat={Infinity}
              />
            <UilSearchIcon className="sIcon"/>
        </button>
        <h3>  </h3>
        <div className='appRow'>
          <Button className='AppButton'
          >
            <GooglePlayBadge className='Badge'></GooglePlayBadge>  
          </Button>
          <Button className='AppButton'>
            <AppStoreBadge className='Badge'></AppStoreBadge>
          </Button>
        </div>
        <p>Coming soon</p>
      </div>
    </div>
  );
}

export default CatchyPhrasePage;
