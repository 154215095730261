import React from 'react';
import logoM from '../../assets/mysquare.png';
import FancyText from '@carefully-coded/react-text-gradient';
import Grid from '@mui/material/Grid';
import './introMysquare.css';
import { Stack } from '@mui/material';
import ImageGradient from './features/imageGradient';

const IntroMysquare = () => {
    return (
        
        
        <Grid 
        container 
        alignItems={"center"} 
        justifyContent={"space-evenly"}
        justify={"flex-end"}
        display={"flex"}
        className='appPresentation'
        gridTemplateColumns={"repeat(auto-fill, minmax(200px, 1fr))"}
        color={"#fff"}
    >  
            <div className='intro' >
                <div className="divider"></div>
                
            <FancyText
                        infinite={true}
                        gradient={{ from: 'rgba(255, 0, 153, 1)', to: 'rgba( 0, 106, 255, 1)', type: 'linear' }}
                        ></FancyText>
                        <h1><b>What am I looking at?</b>
                        </h1>
                    <p className='paragraph'>
                    MySquareAi is a platform that uses <b>AI</b>🤖 to understand your prompt and find the person that fits them.
                    <br/><br/>Perfectly.<br/><br/>Connect with anyone with your own language.
                    
                    </p>
                <div className="gradApp-circle-blueintro"></div>
                <div className="gradApp-circle-pinkintro"></div>
            </div>
        </Grid>
    );
};

export default IntroMysquare;