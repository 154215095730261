import React, {useState} from 'react';
import './form.css';
import { collection, addDoc, getDocs, getFirestore, query, setDoc, doc } from "firebase/firestore";
import {getDatabase} from 'firebase/database';
import {db} from '../../firebase';
import { Firestore } from 'firebase/firestore';
import { Query } from 'firebase/firestore';
import { where } from 'firebase/firestore';
import emailIcon from '../../assets/emailIcon.png';
import toast, { Toaster } from 'react-hot-toast';
import { Stack } from '@mui/material';
import UilInstagram from '@iconscout/react-unicons/icons/uil-instagram.js';
import UilYoutube from '@iconscout/react-unicons/icons/uil-youtube.js';
import UilTwitter from '@iconscout/react-unicons/icons/uil-x.js';
import { subscribeAPI } from '../../services/helloSender';
import bttn from './bttn.jsx';

const Form = () => {
    const [email, setEmail] = useState('');
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handleButtonClick = () => {
      if (email.trim() !== '') {
        writeEmailToFirestore(email);
      } else {
        // Handle empty email case if needed
        console.error('Email cannot be empty');
      }
    };
  
    const writeEmailToFirestore = async (email) => {
      try {
        // Check if a document with the provided email already exists
        // const dbs = getFirestore()
        const c = collection(db, "email_landing")
        const querySnapshot = query(c, where("email", "==", email));
        const docs = await getDocs(querySnapshot);
        if (!docs.empty) {
            console.log("Email already exists in Firestore");
            docs.forEach(async (docc)=>{
              if(docc.data()["newsLetter"]){
                return toast('You are already subscribed!', {
                  icon: '🤩',
                }); 
              }
              try {
                subscribeAPI(email)
                await setDoc(doc(c,docc.id), {
                  email:email,
                  newsLetter: true
                });
              } catch (error) {
                return toast('Sorry, error subscribing', {
                  icon: '❌',
                });
              }
              return toast('Thank you!', {
                icon: '🥳',
              });// Exit the function if email already exists
            });
        }
          else{
            subscribeAPI(email)
            const docRef = await addDoc(collection(db, "email_landing"), {
              email: email,
              newsLetter: true, //TODO: Unsubscribe feature
            });
            console.log('Email written to Firestore');
            return toast('Thank you!', {
              icon: '🥳',
            });
        }
      } catch (error) {
        console.error('Error writing email to Firestore: ', error);
      }
    };
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    
    const handleSubmit = async (event) => {
      event.preventDefault(); // Prevents the default form submission behavior
      if(email.trim()==''){
        console.log('Email cannot be empty');
        return toast('Empty email', {
          icon: '🤔',
        });
      }
      if(!isValidEmail(email)){
        return toast('not an email', {
          icon: '🤔',
        });
      }
      await writeEmailToFirestore(email.trim());
      setEmail(''); // Clear the input field after submitting
  };
  
    return (
        <div className="container">
            <div className="divider"></div>
            <img src={emailIcon} alt="emailIcon" className="emailIcon"/>
            <h1>Join us, help connect the humanity better.</h1>
            <p className='parForm'>Subscribe to our newsletter and be the first to know about our latest features. No spam. I promise.</p>
                <div className="form-group">
                    <input type="email" id="email" name="email" placeholder='Email' className='emailField' value={email} onChange={handleEmailChange}/>
                    <button type="submit" className='newsLBtn' onClick={handleSubmit}>Submit</button>
                </div>
            <h2>Follow us!</h2>
            <Toaster/>
            <Stack
              alignItems="center" 
              direction="row" 
              gap={3}
              color={"#fff"}
              justifyContent={"center"}>
                <div className='Icon' color='#fff'><UilInstagram width="90%" height="90%" color="#fff"></UilInstagram></div>
                <UilYoutube width="50px" height="50px"></UilYoutube>
                <UilTwitter width="50px" height="50px"></UilTwitter>
            </Stack>
            <bttn {...()=>{}}></bttn>
            <div style={{paddingBottom:"9%"}}></div>
        </div>
    );
};

export default Form;