import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Menu } from '@mui/material';
import { MenuButton } from '@mui/base';
import UilArrow from '@iconscout/react-unicons/icons/uil-arrow-right';
import { useNavigate } from 'react-router-dom';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const pages = ['Contribute❤️', 'Who', 'DevBlog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const scrollToEmail = () => {
    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: 'smooth',
    // });
    scroll.scrollToBottom(400)
    setAnchorElNav(null);
};

const navigate = useNavigate();

const openPage = () => {
  navigate('/DevBlog');
};


  return (
    <AppBar position="absolute" sx={{ backgroundColor: '#000', fontFamily: "Ubuntu" }}>
      <Container maxWidth="100vw" >
        <Toolbar disableGutters>
        <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Ubuntu',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MySquareAi
          </Typography>

          <Box sx={{ flexGrow: 1, background:"#000", display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              { pages.concat(["Subscribe"]).map((page) => (
                <MenuItem 
                    key={page}
                    onClick={
                      () =>{
                        if(page == "Subscribe") { scrollToEmail()};
                        if(page == "DevBlog") {
                          openPage();
                        }
                        else if(page == "Contribute❤️"){
                          window.open("https://forms.gle/iyLvFLcpbuD3Dv338", "_blank");
                        }
                        else if(page == "Who") {
                          navigate('/Who');
                        }
                        else{
                          handleCloseNavMenu();
                        }
                    }
                  }
                >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Ubuntu',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            MySquareAi
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={
                  ()=>{
                    if(page == "Subscribe") { scrollToEmail()}
                    else if(page == "DevBlog") {
                      openPage();
                    }
                    else if(page == "Contribute❤️"){
                      window.open("https://forms.gle/iyLvFLcpbuD3Dv338", "_blank");
                    }
                    else if(page == "Who") {
                      navigate('/Who');
                    }
                    else{
                      handleCloseNavMenu();
                    }
                  }
                }
                sx={{ my: 2, color: 'white', display: 'block',textTransform: 'none' }}
              >
                {page}
              </Button>
            ))}
            <Tooltip title="🥳">
              <Button sx={{my:2,
                fontFamily: 'Ubuntu',
                color: "#fff",
                marginLeft: "60%", 
                marginRight: "20px",
                background: "linear-gradient(90deg, #EB00FF, #009FFF);",
                textTransform: 'none' 
            }}
            onClick={scrollToEmail}
            >
                Subscribe
                <UilArrow></UilArrow>
              </Button>
            </Tooltip>
              
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
